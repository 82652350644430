// configuration
@use '@angular/material' as mat;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';
// Layout & components
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/helpers';
// this is needed to _use_ the utilities...
@import '~bootstrap/scss/utilities/api';
@import '~bootstrap/scss/images';

@import 'fonts';
@import 'palettes';
@import 'mat-troodi-theme';
@import 'responsive';

@include mat.core();
@include mat.all-component-typographies($mat-troodi-typography);
@include mat.all-component-themes($mat-troodi-app-theme);

html {
  @include media-breakpoint-up(sm) {
    font-size: 17px;
  }
}

body {
  margin: 0;
  font-family: Muli, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: color('troodi', 'secondary-dark');
  background: color('troodi', 'secondary-light');
  line-height: 20pt;
  @include media-breakpoint-up(sm) {
    line-height: 22pt;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Nunito;
  text-transform: uppercase;
  line-height: 1.2;
  @include media-breakpoint-only(xs) {
    line-height: 16pt;
  }
}

h1 {
  font-weight: 700;
  font-size: 28px;
  @include media-breakpoint-down(xxl) {
    font-size: 24px;
  }
  @include media-breakpoint-only(xs) {
    font-size: 20px;
  }
}

h2 {
  font-weight: 400;
  font-size: 24px;
  @include media-breakpoint-only(xs) {
    font-size: 18px;
  }
}

h3 {
  font-weight: 700;
  font-size: 28px;
  @include media-breakpoint-down(xxl) {
    font-size: 22px;
  }
  @include media-breakpoint-only(xs) {
    font-size: 16px;
  }
}

h4 {
  font-size: 16px;
  font-weight: 700;
}

p {
  margin: 0;
  padding: 0;
}

* {
  outline: none;
}

.text-accent {
  color: color('troodi', 'primary-red') !important;
  font-weight: 700;
}

button {
  text-transform: uppercase;
}

.primary-tooltip {
  background-color: color('troodi', 'secondary-dark');
  font-weight: 700;
  line-height: 14pt;
}

snack-bar-container {
  font-weight: 700;

  &.error {
    background: color('troodi', 'error');
    color: color('troodi', 'secondary-light');

    .mat-simple-snackbar-action {
      color: color('troodi', 'secondary-light');
    }
  }

  &.success {
    background: color('troodi', 'success');
    color: color('troodi', 'secondary-dark');

    .mat-simple-snackbar-action {
      color: color('troodi', 'secondary-dark');
    }
  }

  &.notify {
    background: color('troodi', 'accent');
    color: color('troodi', 'secondary-dark');

    .mat-simple-snackbar-action {
      color: color('troodi', 'secondary-dark');
    }
  }
}

.mat-flat-button,
.mat-raised-button,
.mat-stroked-button {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
}

.mat-stroked-button {
  /* Increase Border-width for all stroked buttons */
  border-width: 2px !important;
}

.cookie-banner {
  // overwrite all material-widths, this banner is supposed to be full-width
  width: 100vw !important;
  min-width: unset !important;
  max-width: unset !important;
}

.cookies-blocked {
  height: 100%;
  width: 100%;
  background: color('troodi', 'secondary-dark');
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;

  h2 {
    text-align: center;
  }
}

a {
  text-decoration: none;
}

.custom-video-dialog {
  @include media-breakpoint-only(xs) {
    // for mobiles, this dialog needs to be larger
    // overwrite standard-value for material dialogs
    max-width: 90vw !important;
  }

  mat-dialog-container {
    padding: 0;
    border-radius: 0;
  }
}

.custom-wide-dialog {
  // Sometimes, the dialogs need to be wider. Dialogs with thos class can take up (almost) 100% of the screen-width if needed.
  // overwrite standard-value for material dialogs
  max-width: 99vw !important;
}

.custom-narrow-dialog {
  // Dialogs containing only notices or messages look more readable (and better overall) if the text-columns are shorter.
  // Use this class for them
  max-width: 380px !important;
}

.cdk-overlay-darker-backdrop {
  // Make even darker backdrops for dialogs/overlays possible
  background-color: rgba(color('shades', 'black'), 0.5);
}

.textlink {
  // for textlinks all around the page
  color: color('troodi', 'primary-red');
  &:hover {
    color: color('troodi', 'primary-red');
  }
}

.alignment-wrapper {
  /* 
    * This is a way to surpass flexbox's weird grow-behaviour (larger elements grow more than smaller ones)
    * and still have the elements correctly centered around the middle-element ([left]---[centered]---[right]).
    * Root of this is a flex-container, the three children need `flex: 1` to get the same amount of space regardless of size AND have to be
    * flex-containers themselves!
    * Elements in the left and right containers get `margin: auto` on the respective sides to push them to the edges.
    * This also works if you omit the centered-element.
    */
  .left,
  .centered,
  .right {
    flex: 1;
    display: flex;
  }

  .left > *:last-child {
    margin-right: auto;
  }

  .centered {
    justify-content: center;
  }

  .right > *:first-child {
    margin-left: auto;
  }
}

// additional (optional) class for mat-checkbox, so that labels will wrap
mat-checkbox.text-wrap {
  .mat-checkbox-inner-container {
    // also, increase padding as it will look cramped with multiline-text
    margin-right: 16px;
  }

  label.mat-checkbox-layout {
    white-space: normal;
    // TODO: maybe check if this is something we want for other inputs / all checkboxes or smth
    // make fontsize smaller, so it won't be cramped as much
    font-size: 16px;
  }
}

// This is the honeypot-class that tries to hide inputs from human eyes without being too obvious for stupid bots
// And syrup can be a very good alternative to honey :D
.syrupish {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}
