@font-face {
  font-family: 'Muli';
  src: url('../fonts/muli-regular/Muli-Regular.eot');
  src: url('../fonts/muli-regular/Muli-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/muli-regular/Muli-Regular.woff2') format('woff2'),
  url('../fonts/muli-regular/Muli-Regular.woff') format('woff'),
  url('../fonts/muli-regular/Muli-Regular.ttf') format('truetype'),
  url('../fonts/muli-regular/Muli-Regular.svg#Muli-Regular') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Muli';
  src: url('../fonts/muli-bold/Muli-Bold.eot');
  src: url('../fonts/muli-bold/Muli-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/muli-bold/Muli-Bold.woff2') format('woff2'),
  url('../fonts/muli-bold/Muli-Bold.woff') format('woff'),
  url('../fonts/muli-bold/Muli-Bold.ttf') format('truetype'),
  url('../fonts/muli-bold/Muli-Bold.svg#Muli-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Nunito';
  src: url('../fonts/nunito-regular/Nunito-Regular.eot');
  src: url('../fonts/nunito-regular/Nunito-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/nunito-regular/Nunito-Regular.woff2') format('woff2'),
  url('../fonts/nunito-regular/Nunito-Regular.woff') format('woff'),
  url('../fonts/nunito-regular/Nunito-Regular.ttf') format('truetype'),
  url('../fonts/nunito-regular/Nunito-Regular.svg#Nunito-Regular') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Nunito';
  src: url('../fonts/nunito-bold/Nunito-Bold.eot');
  src: url('../fonts/nunito-bold/Nunito-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/nunito-bold/Nunito-Bold.woff2') format('woff2'),
  url('../fonts/nunito-bold/Nunito-Bold.woff') format('woff'),
  url('../fonts/nunito-bold/Nunito-Bold.ttf') format('truetype'),
  url('../fonts/nunito-bold/Nunito-Bold.svg#Nunito-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}